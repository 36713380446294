import { API, Auth } from 'aws-amplify'
import axios from 'axios'
import aws_config from '../aws-config'
import { DASHBOARD_REPORT_REQUEST_LIMIT, REPORT_REQUEST_LIMIT } from '../constants'
import * as auth from './auth'
import * as availability from './availability'
import calculator from './calculator'
import config from './config'
import feedback from './feedback'
import formLists from './formLists'
import * as idassociations from './idassociations'
import orders from './orders'
import permitFees from './permitFees'
import products from './products'
import profile from './profile'
import profileTable from './profileTable'
import * as projects from './projects'
import publicConfig from './publicConfig'
import publicHubspot from './publicHubspot'
import surveys from './surveys'
import teams from './teams'

const AMPLIFY_API = 'occapi'
const reportApi = 'https://vbe2j2luz8.execute-api.us-east-1.amazonaws.com/prod/v1'

/* eslint-disable  */
var second,
  seconds = 1
var minute,
  minutes = 60 * seconds
var hour,
  hours = 60 * minutes
var day,
  days = 24 * hours
/* eslint-enable  */

const api = {
  idassociations,
  auth,
  availability,
  config,
  orders,
  products,
  profile,
  profileTable,
  teams,
  projects,
  formLists,
  feedback,
  publicConfig,
  publicHubspot,
  permitFees,
  calculator,
  surveys,
  getLastUpdate: () => API.get(AMPLIFY_API, '/codeReports/lastUpdate'),

  getMyProjectList: async (details = {}) => {
    const user = await Auth.currentUserInfo()
    if (!user) {
      return
    }
    const { teamId, pageNumber, limit, sortBy, order } = details
    const searchParams = new URLSearchParams({
      teamId,
      pageNumber,
      limit: limit ?? DASHBOARD_REPORT_REQUEST_LIMIT,
      sortBy,
      order
    }).toString()
    const path = `/v2/projects/myProjects?${searchParams}`
    try {
      return await API.get(AMPLIFY_API, path)
    } catch (e) {
      throw Error({ error: 'No Reports Found.' })
    }
  },

  projectsByOwnership: async (details = {}) => {
    const user = await Auth.currentUserInfo()
    if (!user) {
      return
    }
    const { ownership, teamId, pageNumber, limit, sortBy, order, term } = details
    const searchParams = new URLSearchParams({
      ownership,
      userId: user.id,
      teamId,
      pageNumber,
      limit,
      sortBy,
      order,
      term
    }).toString()
    const path = `/v2/projects/projectsByOwnership?${searchParams}`
    try {
      return await API.get(AMPLIFY_API, path)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('API - projectsByOwnership error', { e })
      throw Error({ error: 'No Reports Found.' })
    }
  },

  projectsSearchAfter: async (details = {}) => {
    const user = await Auth.currentUserInfo()
    if (!user) {
      return
    }
    const { ownership, teamId, pageNumber, limit, sortBy, order, term, searchAfterIndex } = details
    const searchParams = new URLSearchParams({
      ownership,
      userId: user.id,
      teamId,
      pageNumber,
      limit,
      sortBy,
      order,
      term,
      searchAfterIndex
    }).toString()
    const path = `/v2/projects/projectsSearchAfter?${searchParams}`
    try {
      return await API.get(AMPLIFY_API, path)
    } catch (e) {
      throw Error({ error: 'No Reports Found.' })
    }
  },

  getTeamProjectList: async (details = {}) => {
    try {
      const user = await Auth.currentUserInfo()
      if (!user) {
        return
      }
      const { teamId, pageNumber, limit, sortBy, order } = details
      if (!teamId) {
        return {
          Items: [],
          hasMore: false,
          Count: 0,
          error: 'Please provide a Team Id'
        }
      }
      const searchParams = new URLSearchParams({
        teamId,
        pageNumber,
        limit: limit ?? DASHBOARD_REPORT_REQUEST_LIMIT,
        sortBy,
        order
      }).toString()
      const path = `/v2/projects/teamProjects?${searchParams}`

      return await API.get(AMPLIFY_API, path)
    } catch (e) {
      throw Error({ error: 'No Reports Found.' })
    }
  },

  getFullTeamProjectList: async (details = {}) => {
    const user = await Auth.currentUserInfo()
    if (!user) {
      return
    }
    const { teamId, pageNumber, limit, sortBy, order, term } = details
    const searchParams = new URLSearchParams({
      teamId,
      pageNumber,
      limit: limit ?? DASHBOARD_REPORT_REQUEST_LIMIT,
      sortBy,
      order,
      term
    }).toString()
    const path = `/v2/projects/teamFullProjects?${searchParams}`
    try {
      return await API.get(AMPLIFY_API, path)
    } catch (e) {
      throw Error({ error: 'No Reports Found.' })
    }
  },

  getReportsV2: async (details = {}) => {
    const user = await Auth.currentUserInfo()
    if (!user) {
      return
    }
    const { cursorId, ownerIds } = details
    const searchParams = new URLSearchParams({
      view: 'summary',
      cursor_id: cursorId,
      limit: REPORT_REQUEST_LIMIT,
      owner_ids: ownerIds
    }).toString()
    const path = `/v2/projects?${searchParams}`
    try {
      const { cursor_id, items, has_more } = await API.get(AMPLIFY_API, path)

      return { items, cursorId: cursor_id, hasMore: has_more }
    } catch (e) {
      throw Error({ error: 'No Reports Found.' })
    }
  },

  getReports: async () => {
    // move to refreshing reports always for now
    const user = await Auth.currentUserInfo()
    if (!user) {
      return
    }
    const path = `/codeReports?details=standard`
    try {
      const reports = await API.get(AMPLIFY_API, path)

      return { items: reports }
    } catch (e) {
      throw Error({ error: 'No Reports Found.' })
    }
  },

  getReport: async ({ reportNumber } = {}) => {
    const user = await Auth.currentUserInfo()
    if (!user) {
      return undefined
    }

    return API.get(AMPLIFY_API, `/codeReports/${reportNumber}`)
  },

  getPublicRoofDetails: async ({ reportNumber, requestId }) => API.post(AMPLIFY_API, `/public/project/roof-details`, { body: { reportNumber, requestId } }),

  getPublicWeatherSummary: async ({ projectNumber, weatherType }) => API.post(AMPLIFY_API, `/public/project/weather-summary`, { body: { projectNumber, weatherType } }),

  adoptReport: async (number) => {
    try {
      const path = `/codeReports/adopt/${number}`
      const apiResponse = await API.get(AMPLIFY_API, path)

      return apiResponse
    } catch (err) {
      return Promise.reject(err)
    }
  },

  getIWSWatermarkPDF: async (reportNumber) => {
    const path = `/codeReports/pdf/${reportNumber}/iws`
    try {
      const apiResponse = await API.get(AMPLIFY_API, path)

      return apiResponse
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('e', e)

      return Promise.reject(e)
    }
  },

  generatePDF: async (reportNumber) => {
    try {
      const response = await axios.get(`${reportApi}/report/pdf/${reportNumber}`)

      return response.data
    } catch (err) {
      return Promise.reject(err)
    }
  },

  getCodeReportPDF: async (reportNumber) => {
    try {
      const path = `/codeReports/pdf/${reportNumber}`
      const apiResponse = await API.get(AMPLIFY_API, path)

      return apiResponse
    } catch (err) {
      return Promise.reject(err)
    }
  },

  getWindHistoryPDF: async (reportNumber) => {
    try {
      const path = `/codeReports/pdf/${reportNumber}?type=windHistoryHailTrace`
      const response = await API.post(AMPLIFY_API, path, { body: {} })

      return response
    } catch (err) {
      return Promise.reject(err)
    }
  },

  getHailStormPDF: async (reportNumber) => {
    try {
      const path = `/codeReports/pdf/${reportNumber}?type=hailStorm`
      const response = await API.post(AMPLIFY_API, path, { body: {} })

      return response
    } catch (err) {
      return Promise.reject(err)
    }
  },

  getIWSCalculationPDF: async (reportNumber) => {
    const path = `/codeReports/pdf/${reportNumber}?type=iwsCalculation`
    const response = await API.post(AMPLIFY_API, path, { body: {} })

    return response
  },

  getWasteCalculationPDF: async (reportNumber) => {
    const path = `/codeReports/pdf/${reportNumber}?type=wasteCalculation`
    const response = await API.post(AMPLIFY_API, path, { body: {} })

    return response
  },

  getVentilationCalculationPDF: async (reportNumber) => {
    const path = `/codeReports/pdf/${reportNumber}?type=ventilationCalculation`
    const response = await API.post(AMPLIFY_API, path, { body: {} })

    return response
  },

  getDesignBuildingCriteriaPDF: async (reportNumber) => {
    try {
      const path = `/codeReports/pdf/${reportNumber}?type=buildingDesignCriteria`
      const response = await API.post(AMPLIFY_API, path, { body: {} })

      return response
    } catch (err) {
      return Promise.reject(err)
    }
  },

  getManufacturerReportPDF: async (options) => {
    const { reportNumber, manufacturer_id, product_id } = options
    try {
      const path = `/codeReports/pdf/${reportNumber}?type=manufacturer`
      const response = await API.post(AMPLIFY_API, path, { body: { manufacturer_id, product_id } })

      return response
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('ERROR in generatePDF', { e })

      return Promise.reject(e)
    }
  },

  getPermitFeePDF: async (options) => {
    const { reportNumber, permitDetails } = options
    try {
      const path = `/codeReports/pdf/${reportNumber}?type=permit_calculator`
      const apiResponse = await API.post(AMPLIFY_API, path, {
        body: {
          permitDetails
        }
      })

      return apiResponse
    } catch (err) {
      return Promise.reject(err)
    }
  },

  invalidatePDF: async ({ reportNumber, reportType }) => {
    try {
      const path = `/codeReports/pdf/${reportNumber}/invalidate?type=${reportType}`
      const apiResponse = await API.post(AMPLIFY_API, path)
      return apiResponse
    } catch (err) {
      return Promise.reject(err)
    }
  },

  sendPDF: async (options) => {
    const { reportNumber, reportType } = options
    try {
      const path = `/codeReports/pdf/${reportNumber}/send?type=${reportType}`
      const apiResponse = await API.post(AMPLIFY_API, path)
      return apiResponse
    } catch (err) {
      return Promise.reject(err)
    }
  },

  createReport: async (results, querySrc, propertyType, roofType, purchaseObject) => {
    const path = '/codeReports'
    const body = {
      ...results,
      appSource: 'web',
      _version: '4'
    }
    if (querySrc) body.querySrc = querySrc
    if (propertyType) body.propertyType = propertyType
    if (roofType) body.roofType = roofType
    if (purchaseObject) body.purchaseObject = purchaseObject
    try {
      const apiResponse = await API.post(AMPLIFY_API, path, { body })
      if (apiResponse.error) {
        return Promise.reject(apiResponse)
      }

      return apiResponse
    } catch (e) {
      return Promise.reject(e)
    }
  },

  createAnonymousReport: async (results) => {
    const path = '/public/reports'
    const { endpoint } = aws_config.aws_cloud_logic_custom.find(e => e.name === AMPLIFY_API)
    try {
      const apiResponse = await axios.post(endpoint + path, results)
      if (apiResponse.error) {
        return Promise.reject(apiResponse)
      }
      if (apiResponse.data?.unsupported) {
        return Promise.reject(apiResponse)
      }

      return apiResponse.data.report
    } catch (e) {
      return Promise.reject(e)
    }
  },

  deleteReport: async (number) => {
    const path = `/codeReports/${number}`
    try {
      const apiResponse = await API.del(AMPLIFY_API, path)

      return apiResponse
    } catch (e) {
      return Promise.reject({ error: 'Unable to delete report.' })
    }
  },

  archiveReport: async (number) => {
    const path = `/codeReports/${number}`
    try {
      const apiResponse = await API.post(AMPLIFY_API, path, { body: { archive: true } })

      return apiResponse
    } catch (e) {
      return Promise.reject({ error: 'Unable to archive report' })
    }
  },

  unarchiveReport: async (number) => {
    const path = `/codeReports/${number}`
    try {
      const apiResponse = await API.post(AMPLIFY_API, path, { body: { unarchive: true } })

      return apiResponse
    } catch (e) {
      return Promise.reject({ error: 'Unable to unarchive report' })
    }
  },

  getTeamMetaInfo: async (team_id) => {
    const path = `/public/team/${team_id}`
    const { endpoint } = aws_config.aws_cloud_logic_custom.find(e => e.name === AMPLIFY_API)
    try {
      const apiResponse = await axios.get(endpoint + path)
      if (apiResponse.error) {
        return Promise.reject(apiResponse)
      }

      return apiResponse.data
    } catch (e) {
      return Promise.reject(e)
    }
  },

  verifyEmailAvailable: async (emails) => {
    const path = '/public/reports/email-verification'
    const { endpoint } = aws_config.aws_cloud_logic_custom.find(e => e.name === AMPLIFY_API)
    try {
      const apiResponse = await axios.post(endpoint + path, { emails })
      if (apiResponse.error) {
        return Promise.reject(apiResponse)
      }

      return apiResponse.data
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('endpoint + path', e)

      return Promise.reject(e)
    }
  },

  verifyPhoneAvailable: async (phone) => {
    const path = '/public/reports/phone-verification'
    const { endpoint } = aws_config.aws_cloud_logic_custom.find(e => e.name === AMPLIFY_API)
    try {
      const apiResponse = await axios.post(endpoint + path, { phone })

      return apiResponse
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('endpoint + path', e)

      return Promise.reject(e)
    }
  },

  getLastStateUpdate: async () => {
    const path = '/codeReports/lastStateUpdate'
    try {
      const apiResponse = await API.post(AMPLIFY_API, path)

      return apiResponse
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)

      return Promise.reject({ error: 'Unable to get last state update' })
    }
  },

  localStateUpdate: async (states) => {
    const path = '/codeReports/lastStateUpdate/local-state-update'
    try {
      const apiResponse = await API.post(AMPLIFY_API, path, { body: { states } })

      return apiResponse
    } catch (e) {
      return Promise.reject('Unable to update the subscription states')
    }
  },

  updateReportClaim: async (reportNumber, claims) => {
    const path = `/codeReports/${reportNumber}/claims`
    try {
      const apiResponse = await API.post(AMPLIFY_API, path, {
        body: {
          claim_number: claims.claimNumber,
          job_number: claims.jobNumber
        }
      })

      return apiResponse
    } catch (e) {
      return Promise.reject('Unable to update report claim')
    }
  },

  updateReportDate: async (number, projectDates) => {
    const path = `/codeReports/${number}/projectdate`
    try {
      const apiResponse = await API.post('occapi', path, {
        body: {
          project_date: { date: projectDates.date, type: projectDates.type }
        }
      })

      return apiResponse
    } catch (e) {
      return Promise.reject({ error: 'Unable to update reports date' })
    }
  },

  searchReports: async ({ term }) => {
    const path = `/codeReports?term=${term}`
    try {
      const apiResponse = await API.get(AMPLIFY_API, path)

      return apiResponse
    } catch (e) {
      return Promise.reject('Unable to fetch filtered reports')
    }
  },

  getAddressAccess: async (results) => {
    const path = `/codeReports/access-address`
    const { address, source, locationId } = results
    try {
      const apiResponse = await API.post(AMPLIFY_API, path, {
        body: {
          address,
          source,
          locationId
        }
      })

      return apiResponse
    } catch (e) {
      return Promise.reject('Unable to determine if user has access to this address')
    }
  },

  getAddressSuggestions: async (query) => {
    const path = `/address-suggestions`
    try {
      const apiResponse = await API.post(AMPLIFY_API, path, { body: { ...query } })

      return apiResponse
    } catch (e) {
      return Promise.reject('Unable to determine if user has access to this address')
    }
  },

  getSubscriptionPlan: async (query) => {
    await Auth.currentCredentials()
    const path = `/codeReports/subscriptions`
    try {
      const apiResponse = await API.get(AMPLIFY_API, `${path}?userId=${query}`)

      return apiResponse
    } catch (e) {
      return Promise.reject('Unable to determine if user has access to this address')
    }
  },

  updateUserSubcription: async (query) => {
    await Auth.currentCredentials()
    const path = '/codeReports/subscriptions'
    try {
      const apiResponse = await API.put(AMPLIFY_API, path, { body: { ...query } })

      return apiResponse
    } catch (e) {
      return Promise.reject('Unable to determine if user has access to this address')
    }
  }
}

// eslint-disable-next-line no-underscore-dangle
window._api = api

export default api
