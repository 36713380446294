import { CloseIcon, Icon, Pressable } from '@oneclickdata/components'
import { FormField } from '@oneclickdata/occ-components'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import * as yup from 'yup'
import { REQUIRED_BASE_TEXT } from '../../constants'

export const EMAIL_FIELD = 'email'

export const schema = yup.object({
  [EMAIL_FIELD]: yup.string().email('Email does not appear to be valid.').required(REQUIRED_BASE_TEXT)
})

const EmailInput = ({ initialEmail, validateOnSubmit = false, onChangeText, ...props }) => {
  const [shouldFieldBeValidated, setShouldFieldBeValidated] = useState(validateOnSubmit)
  const inputRef = useRef()
  const { control, setValue, formState } = useFormContext()

  useEffect(() => {
    if (initialEmail && initialEmail !== '') {
      setValue(EMAIL_FIELD, initialEmail, { shouldValidate: !validateOnSubmit })
    }

    if (!initialEmail) {
      setShouldFieldBeValidated(validateOnSubmit)
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialEmail])

  const email = useWatch({ control, name: EMAIL_FIELD })

  useEffect(() => {
    if (email === '') {
      setShouldFieldBeValidated(validateOnSubmit)
    }
  }, [email, validateOnSubmit])

  return (
    <Controller
      control={control}
      name={EMAIL_FIELD}
      rules={{
        validate: async (value) => {
          try {
            await schema.validate({ [EMAIL_FIELD]: value })
          } catch (err) {
            return err.message
          }
          return true
        }
      }}
      render={({ field, fieldState }) => {
        if (formState.isValid && email !== '') {
          setShouldFieldBeValidated(true)
        }

        return (
          <FormField.Input
            disabled={formState.isSubmitting}
            ref={inputRef}
            keyboardType="email-address"
            textContentType="emailAddress"
            error={shouldFieldBeValidated && fieldState?.error?.message}
            onChangeText={(text) => {
              field.onChange(text)
              if (onChangeText) {
                onChangeText(text)
              }
            }}
            hideRightElement={!field.value}
            InputRightElement={
              <Pressable
                eventData={{
                  buttonType: 'EmailInputClear'
                }}
                onPress={() => {
                  setValue(EMAIL_FIELD, '', {
                    shouldValidate: !validateOnSubmit
                  })
                  inputRef.current.focus()
                }}>
                <Icon as={CloseIcon} color="$neutral500" size="md" />
              </Pressable>
            }
            value={field.value}
            label="Email"
            onBlur={() => {
              if (field.value) {
                setShouldFieldBeValidated(true)
              }
            }}
            {...props}
          />
        )
      }}
    />
  )
}

export default EmailInput
