import { Auth } from 'aws-amplify'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import * as idassociations from '../idassociations'

const getApiVersion = () => {
  const versions = {
    default: 'v2.dev',
    beta: 'v2.beta',
    master: 'v2'
  }

  return versions[process.env.REACT_APP_ENV] || versions.default
}

const apiVersion = getApiVersion()

export const appleSignIn = async (onSignIn) => {
  try {
    const data = await window.AppleID.auth.signIn()

    const { id_token } = data.authorization
    const expires_at = Date.now() + 7 * 24 * 60 * 60 * 1000

    const decoded = jwt_decode(id_token)

    const user = {
      appleId: decoded.sub,
      email: data?.user?.email,
      givenName: data.user?.name?.firstName,
      familyName: data.user?.name?.lastName,
      ...(data.user?.name && {
        name: `${data.user?.name?.firstName} ${data.user?.name?.lastName}`
      }),
      source: 'federated'
    }
    const { appleId, email } = user
    let userEmail = email
    if (userEmail) {
      await idassociations.storeAppleIDEmail(appleId, userEmail)
    } else {
      userEmail = await idassociations.lookupEmailbyAppleID(appleId) // from lookup
    }
    await Auth.federatedSignIn('appleid.apple.com', { token: id_token, expires_at }, { ...user, email: userEmail })
    if (onSignIn) {
      onSignIn()
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
}

export const googleLogin = async (authorizationCode) => {
    const ENDPOINT = `https://api.oneclickcode.com/${apiVersion}/public/auth/google/login`
    const { data: apiResponse } = await axios.post(ENDPOINT, { authorizationCode })

    const { idToken, refreshToken } = apiResponse

    const decoded = jwt_decode(idToken)
    const user = {
      googleId: decoded.sub,
      email: decoded.email,
      givenName: decoded.given_name,
      familyName: decoded.family_name,
      name: decoded.name || `${decoded.given_name} ${decoded.family_name}`,
      source: 'federated'
    }

    localStorage.setItem(`FederatedServiceProvider.accounts.google.com.${decoded.sub}.refreshToken`, refreshToken)

    const expires_at = decoded.exp * 1000

    await Auth.federatedSignIn('accounts.google.com', { token: idToken, expires_at }, user)
    return user
}

export const googleRefreshSession = async () => {
  const federatedInfo = JSON.parse(localStorage.getItem('aws-amplify-federatedInfo'))
  const localStorageRefreshTokenKey = `FederatedServiceProvider.accounts.google.com.${federatedInfo.user.googleId}.refreshToken`
  const refreshToken = localStorage.getItem(localStorageRefreshTokenKey)

  try {
    const ENDPOINT = `https://api.oneclickcode.com/${apiVersion}/public/auth/google/refresh-session`
    const { data: apiResponse } = await axios.post(ENDPOINT, { refreshToken })

    localStorage.setItem(localStorageRefreshTokenKey, apiResponse.refreshToken)

    const response = {
      token: apiResponse.idToken,
      expires_at: Date.now() + 10000 //decoded.exp * 1000
    }
    return response
  } catch (e) {
    return
  }

}

export const googleLogout = async () => {
  const federatedInfo = JSON.parse(localStorage.getItem('aws-amplify-federatedInfo'))
  const localStorageRefreshTokenKey = `FederatedServiceProvider.accounts.google.com.${federatedInfo.user.googleId}.refreshToken`

  const ENDPOINT = `https://api.oneclickcode.com/${apiVersion}/public/auth/google/logout`
  const { data: apiResponse } = await axios.post(ENDPOINT, { refreshToken: localStorage.getItem(localStorageRefreshTokenKey) })

  return apiResponse
}
