import { Box, Button, ButtonIcon, ButtonText, CircleCounter, CloseIcon, Divider, HStack, Icon, LogoutIcon, Pressable, Text } from '@oneclickdata/components'
import { Logo } from '@oneclickdata/occ-components'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useAvailability } from '../../customHooks/useAvailability'
import PlanBanner from '../organisms/PlanBanner'

const sSecs = 86400 // number of seconds in the day

export const MenuLink = ({ testID, text, icon, indicator, isCta, to, onClick, asButton }) => (
  <Link
    data-testid={testID}
    to={to}
    onClick={(e) => {
      if (asButton) {
        e.preventDefault()
      }
      if (onClick) {
        onClick()
      }
    }}>
    <HStack p="$4" alignItems="center" justifyContent="space-between">
      <HStack alignItems="center">
        {icon && <Icon as={icon} color={isCta ? '$amber500' : '$cyan400'} size="lg" />}
        <Text ml="$3">{text}</Text>
      </HStack>
      <HStack alignItems="center">
        {isCta && (
          <Box px="$2" py="$1" mr="$2" borderRadius="sm" backgroundColor="$amber500">
            <Text size="sm" color="$neutral900" semibold>
              PRO
            </Text>
          </Box>
        )}
        {indicator &&
          React.cloneElement(indicator, {
            color: isCta ? 'secondary.400' : 'primary.400',
            size: 'sm'
          })}
      </HStack>
    </HStack>
  </Link>
)

const Menu = ({ children, profile, plan, onLogout, showCloseIcon, onClose }) => {
  const { data } = useAvailability()

  // eslint-disable-next-line no-unused-vars
  const isTeamAdmin = profile.team?.admin_id === profile.userId
  const activeSubscription = profile.subscriptions?.[0]

  const isTrialPlan = activeSubscription?.type?.includes('trial')
  const BannerPricing = {
    default: true,
    2022.1: true,
    2022.2: true,
    2020.1: false
  }
  // eslint-disable-next-line no-unused-vars
  const bannerPricing = BannerPricing[plan?.ui?.banner_type]


  const getTitle = (userPlan, remainingDays, totalDays) => {
    if (userPlan?.type?.includes('trial')) return userPlan.title
    return remainingDays ? `${totalDays} Day Trial` : 'Activate your plan'
  }

  let totalFree = 0
  let used = 0

  let sourcePlan = activeSubscription?.type === 'trial-plan' ? 'free' : 'monthly'

  if (activeSubscription && profile?.counts?.[sourcePlan]) {
    if (profile.team) {
      if (profile.team.limits?.plan_config?.type === 'bucket') {
        sourcePlan = 'lifetime'
        used = profile.team.counts?.lifetime?.code_reports.quantity.toLocaleString('en-US')
      } else {
        used = profile.team.counts?.monthly?.code_reports.quantity
      }
    } else {
      used = profile.counts[sourcePlan]?.code_reports || 0
    }
    totalFree = activeSubscription.limits?.[sourcePlan]?.code_reports?.quantity
  }

  const planDuration = activeSubscription?.limits?.free?.period?.ms
  const liveTimestamp = activeSubscription?.details?.billing_cycle_anchor == null ? profile.signUpTimestamp : activeSubscription?.details?.billing_cycle_anchor

  const reFormat = {
    month: 'short',
    day: 'numeric'
  }

  let totalDays
  let remainingDays
  // eslint-disable-next-line no-unused-vars
  let reTag
  let reDate

  if (planDuration) {
    const planEndDate = activeSubscription?.limits?.end_date
      ? new Date(activeSubscription.limits.end_date)
      : new Date((activeSubscription?.start_time || profile.signUpTimestamp) + planDuration)
    const differenceInTime = planEndDate - Date.now()
    remainingDays = Math.round(differenceInTime / (1000 * 3600 * 24))
    remainingDays = remainingDays > 0 ? remainingDays : 0

    totalDays = Math.round(planDuration / (1000 * 3600 * 24))

    reDate = planEndDate.setDate(planEndDate.getDate() + totalDays)
    reTag = new Date(reDate).toLocaleDateString('en-us', reFormat)
  } else if (liveTimestamp > 0) {
    // calculate plan's date details given the initial activity TS
    totalDays = activeSubscription.billingPeriod === 'annual' ? 365 : 30
    reDate = new Date(Math.floor(new Date(liveTimestamp)) + totalDays * sSecs)
    reTag = new Date(reDate).toLocaleDateString('en-us', reFormat)
  }

  // eslint-disable-next-line no-unused-vars
  let subscriptionTitle = ''

  if (planDuration && activeSubscription?.pricingType === 'free') {
    subscriptionTitle = getTitle(activeSubscription, remainingDays, totalDays)
  } else {
    subscriptionTitle = activeSubscription?.orderDisplayTitle || activeSubscription?.title || plan?.title
  }

  const is4P = !!data.code_detail?.allocation && !!data.quick_view?.allocation && !!data.weather?.allocation

  // eslint-disable-next-line no-unused-vars
  let pbSubtitle = totalFree ? `${used} of ${totalFree} projects used` : `${used} project${used === 1 ? '' : 's'} used`

  if (is4P) pbSubtitle = null

  const isExpired = activeSubscription?.is_expired

  if (isExpired) pbSubtitle = 'Expired'

  return (
    <Box>
      {showCloseIcon && (
        <HStack p="$4" alignItems="center">
          <Logo variant="stacked" width="32" />
          <Pressable
            eventData={{
              buttonType: 'MenuIconCloseMobileWebHeader'
            }}
            onPress={onClose}
            ml="auto"
            p="$2"
            sx={{
              ':hover': {
                backgroundColor: '$backgroundHover',
                borderRadius: '$2'
              }
            }}>
            <Icon as={CloseIcon} size="xs" color="$cyan500" />
          </Pressable>
        </HStack>
      )}
      {plan && (
        <Box $base-mx="$4" $lg-mx="$0">
          <PlanBanner plan={plan} showRenewTag={!isTrialPlan} showDaysLeft={isTrialPlan} showExpired={isExpired} />
        </Box>
      )}
      {is4P ? (
        <Box $base-mt="$4" $lg-mt="$0">
          <Text size="xs" mx="$4" color="$neutral500" textAlign="left" medium>
            Projects Remaining
          </Text>
          <HStack px="$4" py="$4" alignItems="center" justifyContent="center" gap="$2">
            <CircleCounter
              flex={1}
              minW={90}
              label="Quick View"
              size="sm"
              total={data.quick_view.allocation}
              value={data.quick_view.allocation - data.quick_view.available}
              showRemaining
              showLockOnLimit
              showContainer
            />
            <CircleCounter
              flex={1}
              minW={90}
              label="Code"
              size="sm"
              total={data.code_detail.allocation}
              value={data.code_detail.allocation - data.code_detail.available}
              showRemaining
              showLockOnLimit
              showContainer
            />
            <CircleCounter
              flex={1}
              minW={90}
              label="Weather"
              size="sm"
              total={data.weather.allocation}
              value={data.weather.allocation - data.weather.available}
              showRemaining
              showLockOnLimit
              showContainer
            />
          </HStack>
        </Box>
      ) : null}
      <Divider />
      {children}
      <Divider />
      <Box px="$4">
        <Button testID="qa-signout-btn" onPress={onLogout} gap="$2" variant="link">
          <ButtonIcon as={LogoutIcon} />
          <ButtonText>Sign Out</ButtonText>
        </Button>
      </Box>
    </Box>
  )
}

const mapStateToProps = ({ profile }) => ({ profile })

export default connect(mapStateToProps)(Menu)
