import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react'
import { AnalyticsProvider } from '@oneclickdata/components'
import { focusManager, QueryClientProvider } from '@tanstack/react-query'
import Amplify, { Auth } from 'aws-amplify'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, Navigate } from 'react-router-dom'
import 'source-map-support/register'
import Datasets from './api/AWSIdentityDatasets'
import App from './App'
import aws_config from './aws-config'
import { QueryDevTools } from './components/QueryDevTools'
import './index.css'
import './layoutAndFont.css'
import { queryClient } from './queryClient'
import analytics from './services/analytics'
import store from './store'

focusManager.setEventListener((handleFocus) => {
  // Listen to visibilitychange and focus
  if (typeof window !== 'undefined' && window.addEventListener) {
    window.addEventListener('visibilitychange', handleFocus, false)
  }

  return () => {
    // Be sure to unsubscribe if a new handler is set
    window.removeEventListener('visibilitychange', handleFocus)
  }
})

Amplify.configure(aws_config)
Datasets.configure({ IdentityPoolId: aws_config.aws_cognito_identity_pool_id })

// If we want to report bugs to Honeybadger, we need to set this env variable to false in SecretsManager
const reportData = process.env.REACT_APP_HONEY_BADGER_DISABLE_REPORT_DATA === "false"

export const honeyBadger = Honeybadger.configure({
  apiKey: process.env.REACT_APP_HONEY_BADGER_KEY,
  revision: process.env.REACT_APP_HONEYBADGER_REVISION,
  developmentEnvironments: ['dev', 'beta'],
  environment: process.env.REACT_APP_ENV || 'master',
  // https://docs.honeybadger.io/lib/javascript/guides/environments-and-versions.html#development-environments
  reportData
})

/**
 * Retrieves user access information from the current session.
 *
 * @async
 * @function getUserAccessInfo
 * @returns {Promise<Object>} An object containing user access information.
 * @property {boolean} accessDenied - Indicates if access is denied.
 * @property {string|null} email - The user's email address.
 * @property {string|null} existingAccountType - The type of the existing account.
 * @property {string|null} identityProviderId - The id of the identity provider.
 */
const getUserAccessInfo = async () => {
  try {
    const session = await Auth.currentSession()
    const idToken = session.getIdToken().decodePayload()

    return {
      accessDenied: idToken['custom:accessDenied'] === 'true',
      email: idToken.email,
      existingAccountType: idToken['custom:existingAccountType'],
      identityProviderId: idToken['custom:identityProviderId']
    }
  } catch (e) {
    return {
      accessDenied: false,
      email: null,
      existingAccountType: null,
      identityProviderId: null
    }
  }
}

const clearCognitoTokens = () => {
  const lastAuthUserKey = 'CognitoIdentityServiceProvider.'
  const idTokenKey = '.idToken'
  const accessTokenKey = '.accessToken'
  const refreshTokenKey = '.refreshToken'

  // Iterate over local storage keys and remove the relevant items
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith(lastAuthUserKey) || key.endsWith(idTokenKey) || key.endsWith(accessTokenKey) || key.endsWith(refreshTokenKey)) {
      localStorage.removeItem(key)
    }
  })
}

const SAMLProtector = ({ children }) => {
  const [isAccessDenied, setIsAccessDenied] = React.useState(null)
  const [redirectPath, setRedirectPath] = React.useState(null)
  const [redirectState, setRedirectState] = React.useState(null)

  React.useEffect(() => {
    const fetchUserAccessInfo = async () => {
      try {
        const { accessDenied, email, existingAccountType, identityProviderId } = await getUserAccessInfo()

        if (accessDenied) {
          clearCognitoTokens()
          if (existingAccountType === 'cognito-account') {
            setRedirectPath('/login')
            setRedirectState({ email, returningUser: true })
          } else {
            setRedirectPath('/welcome-back')
            setRedirectState({ email, type: existingAccountType, identityProviderId })
          }

          setIsAccessDenied(true)
        } else {
          setIsAccessDenied(false)
        }
      } catch (e) {
        setIsAccessDenied(false)
      }
    }

    fetchUserAccessInfo()
  }, [])

  if (isAccessDenied === null) {
    return null
  }

  if (redirectPath) {
    return <Navigate to={redirectPath} state={redirectState} replace />
  }

  return children
}

/**
 * Observes DOM mutations and automatically adds or updates the `data-hj-allow` attribute
 * for `<input>` elements based on their type.
 * - Sets `data-hj-allow="true"` for all input elements except those with `type="password"`.
 * - Sets `data-hj-allow="false"` for input elements with `type="password"`.
 *
 * Made for Hotjar masking purposes.
 */
const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    mutation.addedNodes.forEach((node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.tagName.toLowerCase() === 'input') {
          if (!node.hasAttribute('data-hj-allow')) {
            node.setAttribute('data-hj-allow', 'true')
          }
          if (node.type === 'password') {
            node.setAttribute('data-hj-allow', 'false')
          }
        }

        const inputs = node.querySelectorAll('input')
        inputs.forEach((input) => {
          if (!input.hasAttribute('data-hj-allow')) {
            input.setAttribute('data-hj-allow', 'true')
          }
          if (input.type === 'password') {
            input.setAttribute('data-hj-allow', 'false')
          }
        })
      }
    })
  })
})
/**
 * Starts observing the document body for changes.
 * - Monitors the addition of child nodes (`childList: true`).
 * - Observes changes within all descendants (`subtree: true`).
 */
observer.observe(document.body, {
  childList: true,
  subtree: true
})

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <HoneybadgerErrorBoundary honeybadger={honeyBadger}>
    <AnalyticsProvider value={analytics}>
      <Provider store={store}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <QueryDevTools />
            <SAMLProtector>
              <App />
            </SAMLProtector>
          </QueryClientProvider>
        </BrowserRouter>
      </Provider>
    </AnalyticsProvider>
  </HoneybadgerErrorBoundary>
)

if (module.hot) {
  module.hot.accept()
}

if (window.Cypress) {
  window.store = store
}
