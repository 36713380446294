import React, { useCallback, useEffect, useRef } from 'react'
import { Animated, Easing, View } from 'react-native'

const styles = {
  mainView: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    alignItems: 'center'
  }
}

const Spinner = ({ shouldLoop = true }) => {
  const opacity = useRef(new Animated.Value(0)).current

  const startAnimation = useCallback(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(opacity, {
          toValue: 1,
          duration: 1000,
          easing: Easing.linear,
          useNativeDriver: true
        }),
        Animated.timing(opacity, {
          toValue: 0,
          duration: 0,
          useNativeDriver: true
        })
      ])
    ).start()
  }, [opacity])

  useEffect(() => {
    if (shouldLoop) {
      startAnimation()
    }

    return () => {
      opacity.stopAnimation()
      opacity.setValue(0)
    }
  }, [shouldLoop, startAnimation, opacity])

  return (
    <View style={styles.mainView}>
      <Animated.Image
        style={{
          overflow: 'visible',
          height: 11,
          width: 42,
          top: 8,
          left: -1,
          opacity: opacity.interpolate({
            inputRange: [0, 0.25, 0.5, 0.75, 1],
            outputRange: [0.25, 0, 1, 1, 0.5]
          })
        }}
        source={require('../../../images/misc/roofTops@3x.png')}
      />
      <Animated.Image
        style={{
          height: 11,
          width: 42,
          opacity: opacity.interpolate({
            inputRange: [0, 0.25, 0.5, 0.75, 1],
            outputRange: [0.5, 0.25, 0, 1, 1]
          }),
          overflow: 'visible'
        }}
        source={require('../../../images/misc/roofTops@3x.png')}
      />
      <Animated.Image
        style={{
          height: 11,
          width: 42,
          top: 6,
          left: -10,
          opacity: opacity.interpolate({
            inputRange: [0, 0.25, 0.5, 0.75, 1],
            outputRange: [1, 0.5, 0.25, 0, 1]
          }),
          overflow: 'visible'
        }}
        source={require('../../../images/misc/roofTops@3x.png')}
      />
    </View>
  )
}

export default Spinner
